import React, {useEffect, useRef} from 'react';
import {VideoType} from '../types/VideType';

export interface VideoProps {
  id?: string;
  className?: string;
  loop?: boolean;
  video?: VideoType;
  controls?: boolean;
  autoPlay?: boolean;
}

const Component = React.forwardRef<HTMLVideoElement, VideoProps>(
  ({video, ...props}, ref) => {
    const v = video
      ? video.sources.filter((s) => s.mimeType === 'video/mp4')
      : [];

    const s = v.length ? (v.length >= 2 ? v[1] : v[0]) : undefined;

    return (
      <video
        {...props}
        ref={ref}
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        poster={video?.previewImage.url}
        muted={true}
        playsInline
      >
        {s ? <source src={s.url} type={s.mimeType} /> : null}
      </video>
    );
  }
);

export const Video = React.memo(Component);

import {Dialog, Transition} from '@headlessui/react';
import {ImageType, Image} from '~/features/shared';
import {Fragment, useEffect, useState} from 'react';

export function ImageFullscreenModal({
  onClose,
  isOpen,
  image,
}: {
  isOpen: boolean;
  onClose: () => void;
  image?: ImageType;
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [image?.image.url]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0">
          <div className="flex justify-center items-center min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className="relative p-2 w-full max-w-5xl bg-white rounded-lg"
                style={{
                  height: '70vh',
                }}
              >
                <button
                  onClick={onClose}
                  className="absolute top-5 right-5 z-10 p-1 bg-gray-100 bg-opacity-60 rounded-full hover:bg-opacity-80"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-black"
                  >
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="current"
                    />
                  </svg>
                </button>

                <div className="w-full max-w-full h-full">
                  <Image
                    onLoad={() => setIsLoading(false)}
                    className="object-contain w-full h-full"
                    image={image}
                    showOriginalImage
                  />

                  {isLoading ? (
                    <div className="flex absolute inset-0 justify-center items-center">
                      <div
                        className="inline-block w-20 h-20 rounded-full border-4 border-current border-solid animate-spin border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

import {twMerge} from 'tailwind-merge';
import {MediaType, Video, Image} from '~/features/shared';

export interface MediaProps {
  id: string;
  media?: MediaType;
  autoPlay?: boolean;
}

export function Media({media, id, autoPlay}: MediaProps) {
  if (media?.mediaContentType === 'VIDEO') {
    return (
      <Video
        video={media}
        id={`video-${id}`}
        autoPlay={autoPlay}
        loop
        controls
        className="object-cover absolute inset-0 w-full h-full"
      />
    );
  }

  if (media) {
    return (
      <Image
        id={`image-${id}`}
        className={twMerge(
          'absolute inset-0 w-full h-full',
          media.image.objectFit === 'contain'
            ? 'object-contain'
            : 'object-cover bg-gray-600'
        )}
        image={media}
        loading="eager"
        // width="1000"
        // height="700"
      />
    );
  }

  return null;
}

export function IconFullscreen({className}: {className?: string}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="black"
      className={className}
    >
      <path
        d="M5 19V14H7V17H10V19H5ZM5 10V5H10V7H7V10H5ZM14 19V17H17V14H19V19H14ZM17 10V7H14V5H19V10H17Z"
        fill="current"
      />
    </svg>
  );
}

import {CSSProperties, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {ImageType} from '../../types/ImageType';
import {MediaType} from '../../types/MediaType';
import {ImageFullscreenModal} from './ImageFullscreenModal';
import {Media} from './Media';
import {MediaSlider} from './MediaSlider';

export interface MediaShowcaseProps {
  media: MediaType[];
  className?: string;
  height: CSSProperties['height'];
  style?: CSSProperties;
  showOnlySlider?: boolean;
}

export function MediaShowcase({
  media,
  className,
  height,
  style,
  showOnlySlider,
}: MediaShowcaseProps) {
  const [image, setImage] = useState<ImageType | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  const onFullscreen = (image: ImageType) => {
    setImage(image);
    setIsOpen(true);
  };

  const renderContent = () => {
    if (showOnlySlider) {
      return null;
    }

    if (media.length <= 2) {
      return null;
    }

    if (media.length >= 4) {
      return (
        <div className="hidden flex-col gap-4 lg:flex w-[24rem]">
          <StaticMediaContainer
            className="basis-[60%]"
            media={media[1]}
            id="static-1"
            onFullscreen={onFullscreen}
          />

          <div className="flex flex-auto gap-4">
            <StaticMediaContainer
              className="flex-1"
              media={media[2]}
              id="static-2"
              onFullscreen={onFullscreen}
            />
            <StaticMediaContainer
              className="flex-1"
              media={media[3]}
              id="static-3"
              onFullscreen={onFullscreen}
            />
          </div>
        </div>
      );
    }

    if (media.length === 3) {
      return (
        <div className="hidden flex-col gap-4 lg:flex w-[18rem]">
          <StaticMediaContainer
            className="flex-1"
            media={media[1]}
            id="static-1"
            onFullscreen={onFullscreen}
          />
          <StaticMediaContainer
            className="flex-1"
            media={media[2]}
            id="static-2"
            onFullscreen={onFullscreen}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        className={twMerge(
          'flex gap-4',
          media.length <= 2 && 'lg:justify-center',
          className
        )}
        style={{
          ...style,
          height,
        }}
      >
        <MediaSlider
          className={twMerge('flex-1', media.length <= 2 && 'lg:max-w-[80%]')}
          media={media}
          onFullscreen={onFullscreen}
          height={height}
        />
        {renderContent()}
      </div>
      <ImageFullscreenModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        image={image}
      />
    </>
  );
}

function StaticMediaContainer({
  media,
  id,
  className,
  onFullscreen,
}: {
  media: MediaType;
  id: string;
  className?: string;
  onFullscreen: (image: ImageType) => void;
}) {
  if (media.mediaContentType === 'IMAGE') {
    return (
      <button
        className={twMerge('overflow-hidden relative rounded-md', className)}
        onClick={() => onFullscreen(media)}
      >
        <Media media={media} id={id} />
      </button>
    );
  }

  return (
    <div className={twMerge('overflow-hidden relative rounded-md', className)}>
      <Media media={media} id={id} />
    </div>
  );
}

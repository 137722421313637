import {Splide, SplideSlide} from '@splidejs/react-splide';
import {CSSProperties, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {IconFullscreen} from '~/components/IconFullscreen';
import {ImageType} from '../../types/ImageType';
import {MediaType} from '../../types/MediaType';
import {Media} from './Media';

export function MediaSlider({
  media,
  height,
  onFullscreen,
  className,
}: {
  media: MediaType[];
  height: CSSProperties['height'];
  onFullscreen: (image: ImageType) => void;
  className?: string;
}) {
  const ref = useRef<Splide | null>(null);
  const [showFullscreen, setShowfullscreen] = useState(
    media.length ? media[0].mediaContentType === 'IMAGE' : false
  );

  return (
    <div className={twMerge('relative', className)}>
      <Splide
        ref={ref}
        className="overflow-hidden rounded-md"
        options={{
          fixedHeight: height,
          pagination: false,
          rewind: true,
        }}
        onMove={(splide) => {
          const index = splide.index;
          const m = media[index];
          setShowfullscreen(m.mediaContentType === 'IMAGE');
        }}
      >
        {media.map((m, i) => (
          <SplideSlide key={i}>
            <Media media={m} id={'slide-' + i.toString()} autoPlay={i === 0} />
          </SplideSlide>
        ))}
      </Splide>

      {showFullscreen ? (
        <FullscreenButton
          onClick={() => {
            const index = ref.current?.splide?.index;

            if (index !== undefined) {
              onFullscreen(media[index] as ImageType);
            }
          }}
        />
      ) : null}
    </div>
  );
}

function FullscreenButton({onClick}: {onClick: () => void}) {
  return (
    <button
      className="absolute right-2 bottom-2 bg-gray-100 bg-opacity-60 hover:bg-opacity-80"
      onClick={onClick}
    >
      <IconFullscreen />
    </button>
  );
}
